import * as React from "react";
import Relationship from "../elements/Relationship";

export default class FormRelationship extends React.Component {
    controlRef = React.createRef();

    isValid() {
        return this.controlRef.current.isValid(this.props.value);
    }
    render() {
        const {value, schema, uiSchema} = this.props;

        return (
            <div className="row">
                <div className={uiSchema.size || 'col-md-6'}>
                    <div className="form-group">
                        <label className="form-label">{schema.title}</label>
                        <span className="help">e.g. "5000"</span>

                        <Relationship
                            schema={schema}
                            uiSchema={uiSchema}
                            onChange={this.props.onChange}
                            selectedItem={value}
                            ref={this.controlRef}
                        />

                    </div>
                </div>
            </div>
        )
    }
}
