import React, {useEffect, useState} from "react";
import Box from "../../components/layout/Box";
import {backend} from '../../_config/index'
import req from "../../utils/req";

export function Account(props) {
    const entityName = `account`;

    const [account, setAccount] = useState()


    async function fetchData() {
        req.get(`${backend}/${entityName}s/${props.match.params.id}`).then((res) => {
            setAccount(res.data)
        })
    }

    useEffect(() => {
        fetchData()
    }, []);

    return (!account && <div>Loading</div>) || (<div>
        <Box>
            <box-header>Account</box-header>
            <box-body>
                <div>{account.account_type.name}</div>
                <h1>{account.name}</h1>

                <div className='hide'>
                    CLOSING BALANCE
                    $40,216.09
                </div>
                <div>
                    Description : {account.description}
                </div>
                <h2>
                    Recent Transactions
                </h2>

                <div>
                    <table className="table">
                        <tr>
                            <td>DATE</td>
                            <td>TRANSACTION DETAILS</td>
                            <td>TYPE</td>
                            <td>DEBIT</td>
                            <td>CREDIT</td>
                        </tr>
                    </table>
                </div>
            </box-body>

        </Box>
    </div>)

}
