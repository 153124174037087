import React from 'react';
import './App.scss';
import {Home} from "./pages/Home";
import {SideBarMenu} from "./components/layout/SideBarMenu";
import {Route, Switch} from "react-router-dom";
import {NewContact} from "./pages/NewContact";
import {NewAccount} from "./pages/new/NewAccount";
import {ChartOfAccounts} from "./pages/list/ChartOfAccounts";
import {NewManualJournal} from "./pages/new/NewManualJournal";
import {AccountTypeSummary} from "./reports/AccountTypeSummary";
import {ManualJournals} from "./pages/list/manual-journals";
import {Account} from "./pages/view/Account";
import {Journal} from "./pages/view/Journal";
import PrivateRoute from "./PrivateRoute";
import LoginPage from "./pages/LoginPage";


function App() {
    return (
        <div className="page-container row-fluid">
            <div className="header navbar navbar-inverse">
                <div className="navbar-inner">
                    <div className="header-seperation">
                        <ul className="nav pull-left notifcation-center visible-xs visible-sm">
                            <li className="dropdown">
                                <a href="#main-menu" data-webarch="toggle-left-side">
                                    <i className="material-icons">menu</i>
                                </a>
                            </li>
                        </ul>
                        <a href="index.html">
                            <img src="/assets/img/logo.png"
                                 className="logo" alt=""
                                 data-src="/assets/img/logo.png"
                                 data-src-retina="/assets/img/logo2x.png" width="106" height="21"/>
                        </a>
                        <ul className="nav pull-right notifcation-center hide">
                            <li className="dropdown hidden-xs hidden-sm">
                                <a href="index.html" className="dropdown-toggle active" data-toggle="">
                                    <i className="material-icons">home</i>
                                </a>
                            </li>
                            <li className="dropdown hidden-xs hidden-sm">
                                <a href="email.html" className="dropdown-toggle">
                                    <i className="material-icons">email</i><span className="badge bubble-only"></span>
                                </a>
                            </li>
                            <li className="dropdown visible-xs visible-sm">
                                <a href="#" data-webarch="toggle-right-side">
                                    <i className="material-icons">chat</i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="header-quick-nav">
                        <div className="pull-left">
                            <ul className="nav quick-section">
                                <li className="quicklinks">
                                    <a href="#" className="" id="layout-condensed-toggle">
                                        <i className="material-icons">menu</i>
                                    </a>
                                </li>
                            </ul>
                            <ul className="nav quick-section">
                                <li className="quicklinks  m-r-10">
                                    <a href="#" className="">
                                        <i className="material-icons">refresh</i>
                                    </a>
                                </li>
                                <li className="quicklinks">
                                    <a href="#" className="">
                                        <i className="material-icons">apps</i>
                                    </a>
                                </li>
                                <li className="quicklinks"><span className="h-seperate"></span></li>
                                <li className="quicklinks">
                                    <a href="#" className="" id="my-task-list" data-placement="bottom" data-content=''
                                       data-toggle="dropdown" data-original-title="Notifications">
                                        <i className="material-icons">notifications_none</i>
                                        <span className="badge badge-important bubble-only"></span>
                                    </a>
                                </li>
                                {/*<li className="m-r-10 input-prepend inside search-form no-boarder">*/}
                                {/*    <span className="add-on"> <i className="material-icons">search</i></span>*/}
                                {/*    <input name="" type="text" className="no-boarder " */}
                                {/*           placeholder="Search Dashboard"*/}
                                {/*           style={{width: "250px;"}}/>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                        <div id="notification-list" className='hide'>
                            <div style={{width: '300px'}}>
                                <div className="notification-messages info">
                                    <div className="user-profile">
                                        <img src="/assets/img/profiles/d.jpg"
                                             alt="" data-src="/assets/img/profiles/d.jpg"
                                             data-src-retina="/assets/img/profiles/d2x.jpg" width="35" height="35"/>
                                    </div>
                                    <div className="message-wrapper">
                                        <div className="heading">
                                            David Nester - Commented on your wall
                                        </div>
                                        <div className="description">
                                            Meeting postponed to tomorrow
                                        </div>
                                        <div className="date pull-left">
                                            A min ago
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                        <div className="pull-right hide">
                            <div className="chat-toggler sm">
                                <div className="profile-pic">
                                    <img src="/assets/img/profiles/avatar_small.jpg" alt=""
                                         data-src="/assets/img/profiles/avatar_small.jpg"
                                         data-src-retina="/assets/img/profiles/avatar_small2x.jpg" width="35"
                                         height="35"/>
                                    <div className="availability-bubble online"></div>
                                </div>
                            </div>
                            <ul className="nav quick-section ">
                                <li className="quicklinks">
                                    <a data-toggle="dropdown" className="dropdown-toggle  pull-right " href="#"
                                       id="user-options">
                                        <i className="material-icons">tune</i>
                                    </a>
                                    <ul className="dropdown-menu  pull-right" role="menu"
                                        aria-labelledby="user-options">
                                        <li>
                                            <a href="user-profile.html"> My Account</a>
                                        </li>
                                        <li>
                                            <a href="calender.html">My Calendar</a>
                                        </li>
                                        <li>
                                            <a href="email.html"> My Inbox&nbsp;&nbsp;
                                                <span className="badge badge-important animated bounceIn">2</span>
                                            </a>
                                        </li>
                                        <li className="divider"></li>
                                        <li>
                                            <a href="login.html"><i
                                                className="material-icons">power_settings_new</i>&nbsp;&nbsp;Log Out</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="quicklinks"><span className="h-seperate"></span></li>
                                <li className="quicklinks">
                                    <a href="#" className="chat-menu-toggle" data-webarch="toggle-right-side"><i
                                        className="material-icons">chat</i><span
                                        className="badge badge-important hide">1</span>
                                    </a>
                                    <div className="simple-chat-popup chat-menu-toggle hide">
                                        <div className="simple-chat-popup-arrow"></div>
                                        <div className="simple-chat-popup-inner">
                                            <div style={{width: '100px'}}>
                                                <div className="semi-bold">David Nester</div>
                                                <div className="message">Hey you there</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-sidebar " id="main-menu">
                <div class="page-sidebar-wrapper scrollbar-dynamic" id="main-menu-wrapper">
                    <div class="user-info-wrapper sm hide">
                        <div class="profile-wrapper sm">
                            <img src="/assets/img/profiles/avatar.jpg" alt="" data-src="/assets/img/profiles/avatar.jpg"
                                 data-src-retina="/assets/img/profiles/avatar2x.jpg" width="69" height="69"/>
                            <div class="availability-bubble online"></div>
                        </div>
                        <div class="user-info sm">
                            <div class="username">Fred <span class="semi-bold">Smith</span></div>
                            <div class="status">Life goes on...</div>
                        </div>
                    </div>

                    <SideBarMenu/>

                    <div class="side-bar-widgets hide">
                        <p class="menu-title sm">FOLDER <span class="pull-right"><a href="#" class="create-folder"> <i
                            class="material-icons">add</i></a></span></p>
                        <ul class="folders">
                            <li>
                                <a href="#">
                                    <div class="status-icon green"></div>
                                    My quick tasks </a>
                            </li>
                        </ul>
                        <p class="menu-title">PROJECTS </p>
                        <div class="status-widget">
                            <div class="status-widget-wrapper">
                                <div class="title">Freelancer<a href="#" class="remove-widget"><i
                                    class="material-icons">close</i></a></div>
                                <p>Redesign home page</p>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
            <a href="#" className="scrollup hide">Scroll</a>
            <div className="footer-widget">
                <div className="progress transparent progress-small no-radius no-margin">
                    <div className="progress-bar progress-bar-success animate-progress-bar"></div>
                </div>
                <div className="pull-right">
                    <div className="details-status"><span className="animate-number" data-value="86"
                                                          data-animation-duration="560">86</span>%
                    </div>
                    <a href="lockscreen.html"><i className="material-icons">power_settings_new</i></a></div>
            </div>
            <div className="page-content">
                <div className="content">
                    <noscript>You need to enable JavaScript to run this app.</noscript>
                    <main>
                        <Switch>
                            <PrivateRoute path="/contacts/new" component={NewContact} exact/>

                            <PrivateRoute path="/accounts/new" component={NewAccount} exact/>
                            <PrivateRoute path="/accounts/edit/:id" component={NewAccount} exact/>
                            <PrivateRoute path="/accounts/:id" component={Account} exact/>
                            <PrivateRoute path="/accounts" component={ChartOfAccounts} exact/>

                            <PrivateRoute path="/journals/new" component={NewManualJournal} exact/>
                            <PrivateRoute path="/journals/:id" component={Journal} exact/>
                            <PrivateRoute path="/journals/edit/:id" component={NewManualJournal} exact/>
                            <PrivateRoute path="/journals" component={ManualJournals} exact/>

                            <PrivateRoute path="/reports/accounttypesummary" component={AccountTypeSummary} exact/>
                            <Route path="/" component={Home} exact/>
                            <Route path="/login" component={LoginPage} />

                            {/*<SideBarMenuItem title="New Account" path="/accounts/new"/>*/}
                            {/*<Route path="/about" component={About} />*/}
                            {/*<Route path="/shop" component={Shop} />*/}
                            {/*<Route component={Error} />*/}
                        </Switch>
                    </main>
                </div>
            </div>
        </div>
    )
}

export default App;
