import React, {useEffect, useRef, useState} from "react";
import Box from "../../components/layout/Box";
import {Form} from "../../components/forms/Form";
import axios from "axios";
import {useHistory} from "react-router";
import {backend} from '../../_config/index'
import req from "../../utils/req";

export function EditablePage({pageName, entity, match, schema, uiSchema}) {

    const url = `${backend}/${entity}s`
    const history = useHistory();

    const formRef = useRef(null);
    const [object, setObject] = useState({})


    async function fetchData() {
        req.get(`${url}/${match.params.id}`).then((res) => {
            setObject(res.data)
        })
    }

    useEffect(() => {
        if (match.path.includes('/edit/')) {
            fetchData()
        }
    }, []);

    const onFormChange = (val) => {
        setObject(val);
    }

    const save = (e) => {
        e.preventDefault()
        if (formRef.current.isValid()) {
            if (match.path.includes('/edit/')) {
                req.put(`${url}/${object.id}`, object).then((res) => {
                    history.push(`/${entity}s`);
                })
            } else {
                req.post(url, object).then((res) => {
                    console.log(res);
                    history.push(`/${entity}s`);
                })
            }
        }
    }


    return <div>
        <Box>
            <box-header>${pageName}</box-header>
            <box-body>
                <Form schema={schema} uiSchema={uiSchema} onChange={onFormChange} ref={formRef} value={object}>
                </Form>
            </box-body>
            <box-footer>
                <button type="submit" className="btn btn-success"
                        data-test={`${entity}-save`}
                        onClick={(e) => save(e)}>Save
                </button>
            </box-footer>
        </Box>
    </div>

}
