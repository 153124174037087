import React from 'react';
import $ from 'jquery';
import 'daterangepicker'
import Control from "./Control";
import {ValidationMessage} from "../forms/ValidationMessage";

export default class DatePicker extends Control {
    state = {
        isValid: false,
        isDirty: false,
    };

    constructor() {
        super();
        this.myRef = React.createRef();
    }

    componentDidMount(prevProps, prevState, snapshot) {
        const node = this.myRef.current;
        $(node).daterangepicker({
            autoUpdateInput: false,
            singleDatePicker: true,
            autoApply: true,
            locale: {
                format: 'MMM/DD/YYYY'
            }
        });

        $(node).on('apply.daterangepicker', (ev, picker) => {
            $(node).val(picker.startDate.format('MM/DD/YYYY'))

            this.handleChange(picker.startDate);
        });
    }

    render() {
        const {uiSchema = {}} = this.props;
        const {isValid, isDirty} = this.state;
        const error = !isValid && isDirty;

        return <>
            <input ref={this.myRef} type="text"
                   class="form-control"
                   onBlur={({target: {value}}) => this.handleBlur(value)}
                   required={this.props.required}
                   autoFocus={uiSchema.autofocus}
                   className={this.props.className}/>

            <ValidationMessage schema={this.props.schema} invalid={error} message={this.state.errorMessage}/>
        </>
    }

}
