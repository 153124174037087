import React, {useState} from "react";
import classNames from 'classnames';

function SideBarMenuItem({path, title, addUrl = ''}) {
    const [addVisible, setAddVisible] = useState(false)

    const showAdd = () => {
        setAddVisible(true && !!addUrl)
    }
    const hideAdd = () => {
        setAddVisible(false)
    }

    return <li className='menu-item' onMouseEnter={showAdd} onMouseLeave={hideAdd}>
        <a className='main-url' href={path}>
            <span className="title">{title}</span>
        </a>
        <a className={classNames('add-url', {hide: !addVisible})} href={addUrl}>
            <i className="fa fa-plus"></i>
        </a>

    </li>

    // <li className="start active">
    //     <a href={this.props.path}>
    //         <i className="material-icons">home</i>
    //         <span className="title">{this.props.title}</span>
    //         <span className="selected"/>
    //     </a>
    // </li>;
}

function SideBarMenuGroup(props) {
    const [menuOpened, setMenuOpened] = useState(true)
    const toggle = () => {
        setMenuOpened(!menuOpened);
    }

    return <li className={classNames({open: menuOpened})}>
        <a onClick={toggle}>
            <i className={classNames('fa', props.icon)}></i> <span
            className="title">{props.header}</span> <span className="arrow open"></span>
        </a>
        <ul className="sub-menu" style={{display: menuOpened ? "block" : "none"}}>
            {props.children}
        </ul>
    </li>
}

export function SideBarMenu() {
    return <ul className='side-bar-menu'>
        <SideBarMenuItem title="Dashboard" path="/"/>
        <SideBarMenuItem title="Items" path="/"/>
        <SideBarMenuItem title="Banking" path="/"/>

        <SideBarMenuGroup header='Accountant' icon='fa-money'>
            <SideBarMenuItem title="Manual Journals" path="/journals" addUrl='/journals/new'/>
            <SideBarMenuItem title="Chart Of Account" path="/accounts" addUrl='/accounts/new'/>
        </SideBarMenuGroup>
        <SideBarMenuGroup header='Reports' icon='fa-signal'>
            <SideBarMenuItem title="Account Type Summary" path="/reports/accounttypesummary"/>
        </SideBarMenuGroup>
    </ul>

}
