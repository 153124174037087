import React, {useEffect, useRef, useState} from "react";
import Box from "../../components/layout/Box";
import {Form} from "../../components/forms/Form";
import axios from "axios";
import {useHistory} from "react-router";
import {backend} from "../../_config";
import req from "../../utils/req";

export function NewManualJournal(props) {
    const formRef = useRef(null);
    const history = useHistory();
    const [journal, setJournal] = useState()

    async function fetchData() {
        req.get(`${backend}/journals/${props.match.params.id}`).then((res) => {
            setJournal(res.data)
        })
    }

    useEffect(() => {
        if (props.match.path.includes('/edit/')) {
            fetchData()
        }
    }, []);

    const schema = {
        title: "New Journal",
        type: "object",
        properties: {
            date: {type: "date", title: "Date", required: true},
            notes: {type: "string", title: "Notes", required: true},

            journalItems: {
                type: "array",
                items: {
                    type: "object",
                    properties: {
                        account: {type: "relationship", title: "Account", required: true},
                        description: {type: "string", title: "Description", required: true},
                        debt: {type: "string", title: "Debits"},
                        credit: {type: "string", title: "Credits"},
                    }
                },
                validations: [
                    {
                        message: `At least two journal items are required`,
                        validate: (value) => {
                            return value.length > 1
                        }
                    },
                    {
                        message: `debt and credit`,
                        validate: (value) => {
                            return true
                        }
                    }
                ]
            }
        }
    }

    const uiSchema = {
        notes: {
            type: 'textarea'
        },
        journalItems: {
            account: {
                source: `${backend}/accounts`,
                label: 'name',
                value: "id",
                // groupBy: "account_type"
            },
            description: {
                type: 'textarea'
            }

        }
    }

    const onFormChange = (val) => {
        setJournal(val);
    }

    const save = (e) => {
        e.preventDefault()
        if (formRef.current.isValid()) {
            if (props.match.path.includes('/edit/')) {
                req.put(`${backend}/journals/${journal.id}`, journal).then((res) => {
                    console.log(res);
                    history.push("/journals");
                })
            } else {
                req.post(`${backend}/journals`, journal).then((res) => {
                    history.push("/journals");
                })
            }
        }
    }
    return <div>
        <Box>
            <box-header>Home</box-header>
            <box-body>
                <Form schema={schema} uiSchema={uiSchema} onChange={onFormChange} ref={formRef} value={journal}>
                </Form>
            </box-body>
            <box-footer>
                <button type="submit" className="btn btn-success"
                        onClick={(e) => save(e)}>Save
                </button>
            </box-footer>
        </Box>
    </div>

}
