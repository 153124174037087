import axios from "axios";
import classNames from 'classnames';
import React, {useEffect, useState} from "react";
import req from "../../utils/req";

export function DataGrid({
                             settings: {
                                 entity, url, key = 'id', columns
                             }
                         }) {
    const [items, setItems] = useState([]);

    async function fetchData() {
        req.get(url).then((res) => {
            setItems(res.data)
        })
    }

    useEffect(() => {
        fetchData()
    }, []);

    const deleteAccount = (target) => {
        const id = target.target.getAttribute('data-value')
        req.delete(`${url}/${id}`).then(a => {
            setItems(items.filter(a => a.id !== id))
        })
    }

    return <table className={classNames('table', `${entity}s-table`)}>
        <thead>
        <tr>
            {
                columns.map((c, index) => {
                    return <th key={`${c.path}-${index}`}>{c.title}</th>
                })}
            <th>Edit</th>
            <th>Delete</th>
        </tr>
        </thead>
        <tbody>
        {items.map((itemData, itemIndex) => {
            return <tr key={itemData[key]}>
                {
                    columns.map((column, colIndex) => {
                        if (column.template) {
                            return column.template(itemData, itemIndex, colIndex)
                        }
                        const pathProps = column.path.split('.')
                        let data = ''
                        if (pathProps.length === 1) {
                            data = itemData[pathProps[0]]
                        } else if (pathProps.length === 2) {
                            data = itemData[pathProps[0]][pathProps[1]]
                        }
                        return <td key={`${column.name}-${itemIndex}-${colIndex}`}>{data}</td>
                    })}

                <td><a data-test={`edit-${itemIndex + 1}`} href={`${entity}s/edit/${itemData[key]}`}>Edit</a></td>
                <td><a data-test={`delete-${itemIndex + 1}`} href='#' data-value={itemData[key]} onClick={deleteAccount}>Delete</a></td>
            </tr>
        })}
        </tbody>
    </table>
}
