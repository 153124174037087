import React, {useEffect, useRef, useState} from "react";
import Box from "../../components/layout/Box";
import {backend} from "../../_config";
import req from "../../utils/req";

export function Journal(props) {
    const [journal, setJournal] = useState()


    async function fetchData() {
        req.get(`${backend}/journals/${props.match.params.id}`).then((res) => {
            setJournal(res.data)
        })
    }

    useEffect(() => {
        fetchData()
    }, []);

    return (!journal && <div>Loading</div>) || (<div>
        <Box>
            <box-header>Journal</box-header>
            <box-body>

                <div>
                    {journal.notes}
                </div>
                <div></div>
                May Sales
                <div>
                    Date: {journal.date}
                </div>
                <div>
                    Amount: ${journal.journalItems.reduce((a,i)=> a + i.debt , 0)}
                </div>
                <div>
                    Reference Number:
                </div>

            </box-body>

        </Box>
    </div>)

}
