import React from "react";
import Select from "react-select";
import classNames from "classnames/bind";

const selectStyles = {
    control: provided => {
    },
    valueContainer: provided => ({
            ...provided,
            border: 'none'
        }
    ),
    input: provided => ({
            ...provided,
            width: '100%',
            margin: 0,
            padding: 0,
        }
    ),
    menu: () => ({boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)'}),
};
const Menu = props => {
    const shadow = 'hsla(218, 50%, 10%, 0.1)';
    return (
        <div
            style={{
                backgroundColor: 'white',
                borderRadius: 0,
                boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
                marginTop: 3,
                position: 'absolute',
                zIndex: 2,
            }}
            {...props}
        />
    );
};
const Blanket = props => (
    <div
        style={{
            bottom: 0,
            left: 0,
            top: 0,
            right: 0,
            position: 'fixed',
            zIndex: 1,
        }}
        {...props}
    />
);
const Dropdown = ({children, isOpen, target, onClose}) => (
    <div style={{position: 'relative'}}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
        {isOpen ? <Blanket onClick={onClose}/> : null}
    </div>
);
const Svg = p => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        focusable="false"
        role="presentation"
        {...p}
    />
);

const DropdownIndicator = () => (
    <div style={{color: '#eee', height: 24, width: 32}}>
        <Svg>
            <path
                d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </Svg>
    </div>
);


export class SelectElement extends React.Component {
    state = {
        isValid: false,
        isDirty: false,
        isOpen: false
    };

    isValid(value) {
        const {required, title} = this.props.schema;
        if (required) {
            if (!value) {
                this.setState({errorMessage: `${title} is required`, isValid: false});
                return false;
            }
        }

        this.setState({errorMessage: '', isValid: true});
    }

    handleChange = (value) => {
        this.toggleOpen();

        this.isValid(value);
        return this.props.onChange(value.object);
    };
    handleBlur = (value) => {
        this.isValid(value);
        this.setState({isDirty: true})
    }

    toggleOpen = () => {
        this.setState(state => ({isOpen: !state.isOpen}));
    };

    render() {
        const {selectedItem, options, uiSchema = {}, schema = {title: `value`}} = this.props;
        const {isValid, isDirty, isOpen} = this.state;
        const error = !isValid && isDirty;

        let finalOptions = options;
        if (uiSchema.groupBy) {
            finalOptions = options.flatMap(a => a.options)
        }
        const selectedValue = !!selectedItem? finalOptions.filter(e => e.value === selectedItem[uiSchema.value])[0]: null;
        return (
            <Dropdown
                data-test={`${schema.key}`}
                isOpen={isOpen}
                onClose={this.toggleOpen}
                target={
                    <div onClick={this.toggleOpen} className='ac-selected form-control'
                         data-test={`${schema.key}-select`}>
                        <span>{selectedValue ? `${selectedValue.label}` : `Select ${schema.title.toLowerCase()}`}</span>
                        <i className="fa fa-chevron-down"/>
                    </div>
                }>
                <Select
                    backspaceRemovesValue={false}
                    controlShouldRenderValue={false}
                    isClearable={false}
                    components={{DropdownIndicator, IndicatorSeparator: null}}
                    menuIsOpen={true}
                    autoFocus
                    styles={selectStyles}

                    placeholder="Search..."
                    className={classNames('react-select-container', {error})}
                    classNamePrefix="react-select"
                    required={this.props.required}
                    onChange={this.handleChange}
                    value={selectedValue}
                    options={options}
                />
            </Dropdown>

        )
    }
}
