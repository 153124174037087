import React, {useRef} from "react";
import Box from "../components/layout/Box";
import {Form} from "../components/forms/Form";

export function NewContact() {
    const formRef = useRef();

    const schema = {
        title: "Contacts",
        type: "object",
        required: ["firstName"],

        properties: {
            firstName: {type: "string", title: "First Name", required: true},
            lastName: {type: "string", title: "Last Name"},
        }
    }

    const uiSchema = {
        firstName: {
            size: "col-md-4"
        },
        lastName: {
            size: "col-md-4"
        },

    }

    const onFormChange = (val) => {
        console.log(val)
    }

    const options = [
        {value: 'chocolate', label: 'Chocolate'},
        {value: 'strawberry', label: 'Strawberry'},
        {value: 'vanilla', label: 'Vanilla'}
    ]

    // const submitForm = () => {
    //     console.log(formRef.current.isValid())
    //     // formRef.
    // }

    return <div>
        <Box>
            <box-header>Home</box-header>
            <box-body>
                <Form schema={schema} uiSchema={uiSchema} onChange={onFormChange} ref={formRef}>
                </Form>
            </box-body>
        </Box>
    </div>

}
