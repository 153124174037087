import React from "react";

export default function Box(props) {
    return (<div className="row">
        <div className="col-md-12">
            <div className="grid simple">
                <div className="grid-title">
                    {props.children.filter(a => a.type === 'box-header')}
                </div>
                <div className="grid-body">
                    {props.children.filter(a => a.type === 'box-body')}
                </div>

                <div className="grid-title">
                    {props.children.filter(a => a.type === 'box-footer')}
                </div>
            </div>
        </div>
    </div>)

}
