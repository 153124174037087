import * as React from "react";
import classNames from 'classnames/bind';

import {ValidationMessage} from "../forms/ValidationMessage";
import Control from "./Control";

export default class TextBox extends Control {
    state = {
        isValid: false,
        isDirty: false,
    };

    render() {
        const {value, uiSchema = {}, schema} = this.props;
        const {isValid, isDirty} = this.state;

        const error = !isValid && isDirty;

        const InputType = !!uiSchema.type ? uiSchema.type : 'input'

        return (
            <>
                <InputType className={classNames('form-control', {error})}
                           required={this.props.required}
                           autoFocus={uiSchema.autofocus}
                           value={value || ''}
                           onChange={({target: {value}}) => this.handleChange(value)}
                           onBlur={({target: {value}}) => this.handleBlur(value)}
                           data-test={schema.key}

                />

                <ValidationMessage schema={schema} invalid={error} message={this.state.errorMessage}/>
            </>
        )
    }
}
