import React from "react";
import {EditablePage} from "../common/EditablePage";
import {maxLength, required} from "../../components/validators";
import {backend} from "../../_config";

export function NewAccount(props) {
    const pageName = 'Home'
    const entity = 'account'
    const match = props.match;
    const schema = {
        title: "Accounts",
        type: "object",
        properties: {
            account_type: {
                type: "relationship", title: "Account Type",
                validations: [
                    required('Account Type'),
                ]
            },
            name: {
                type: "string",
                title: "Account Name",
                validations: [
                    required('Account Name'),
                    maxLength(25)
                ]
            },
            code: {
                type: "string", title: "Account Code",
                validations: [
                    required('Account Code'),
                ]
            },
            description: {
                type: "string", title: "Description"
            },
        }
    }
    const uiSchema = {
        account_type: {
            source: `${backend}/account-types`,
            label: 'name',
            value: "id",
            groupBy: "type"
        },
        description: {
            type: 'textarea'
        }
    }
    return <EditablePage pageName={pageName} entity={entity} match={match}
                         schema={schema} uiSchema={uiSchema}/>
}
