import React from "react";
import TextBox from "../elements/TextBox";
import FormRelationship from "./FormRelationship";
import Relationship from "../elements/Relationship";

export class FormArrayRow extends React.Component {
    refs = [];

    isValid() {
        const isValid =
            this.refs.map(a => a.current.isValid())
                .reduce((prev, current) => prev && current, true);
        return isValid;
    }

    onChange(key, value) {
        this.props.onChange(key, value);
    }

    removeItem() {
        this.props.onRemoveItem();
    }

    render() {
        const {schema, uiSchema, item} = this.props;
        this.refs = Object.keys(schema.items.properties).map(a => React.createRef())

        return <tr>
            {
                Object.keys(schema.items.properties).map((k, schemaIndex) => {
                    const v = schema.items.properties[k];
                    const u = uiSchema[k];
                    switch (v.type) {
                        case 'string':
                            return <td>
                                <TextBox key={k}
                                         schema={v}
                                         uiSchema={u}
                                         value={item[k]}
                                         onChange={value => this.onChange(k, value)}
                                         ref={this.refs[schemaIndex]}
                                />
                            </td>
                        case 'relationship':
                            return <td>
                                <Relationship key={k}
                                              schema={v}
                                              uiSchema={u}
                                              selectedItem={item[k]}
                                              onChange={value => this.onChange(k, value)}
                                              ref={this.refs[schemaIndex]}
                                />
                            </td>
                    }

                })
            }
            <td>
                <input type="text" className="btn btn-danger" value="Delete"
                       onClick={() => this.removeItem()}/>
            </td>
        </tr>
    }
}
