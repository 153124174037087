import * as React from "react";
import axios from 'axios';
import {SelectElement} from "../elements/SelectElement";
import {ValidationMessage} from "../forms/ValidationMessage";
import Control from "./Control";
import {groupBy} from "lodash";
import req from "../../utils/req";

export default class Relationship extends Control {
    state = {
        isValid: false,
        isDirty: false,
        items: []
    };

    componentDidMount() {
        req.get(this.props.uiSchema.source).then((res) => {
            if (this.props.uiSchema.groupBy) {
                const grouped = groupBy(res.data, 'type')
                const items = Object.keys(grouped).map(key => {
                    const options = grouped[key].map(d => {
                        return {
                            label: d[this.props.uiSchema.label],
                            value: d[this.props.uiSchema.value],
                            object: d
                        }
                    })
                    return {
                        label: key,
                        options
                    }
                })

                this.setState({items})
            } else {
                const items = res.data.map(d => {
                        return {
                            label: d[this.props.uiSchema.label],
                            value: d[this.props.uiSchema.value],
                            object: d
                        }
                    }
                )
                this.setState({items})
            }
        })
    }


    render() {
        const {selectedItem, schema, uiSchema = {}} = this.props;
        const {isValid, isDirty, items} = this.state;
        // let convertedValue = value;
        // if (value) {
        //     if (!value.label) {
        //         convertedValue = {
        //             label: value[this.props.uiSchema.label],
        //             value: value[this.props.uiSchema.value]
        //         }
        //     }
        // }

        // console.log({convertedValue})
        const error = !isValid && isDirty;

        return (
            <>
                <SelectElement
                    schema={schema}
                    uiSchema={uiSchema}
                    onChange={this.handleChange}
                    selectedItem={selectedItem}
                    options={items}
                />
                <ValidationMessage schema={schema} invalid={error} message={this.state.errorMessage}/>
            </>
        )
    }
}
