import React from "react";
import DatePicker from "../elements/DatePicker";
import {ValidationMessage} from "./ValidationMessage";

export class FormDate extends React.Component {
    controlRef = React.createRef();

    isValid() {
        return this.controlRef.current.isValid(this.props.value);
    }

    render() {
        const {value, schema, uiSchema = {}} = this.props;

        return (
            <div className="row">
                <div className={uiSchema.size || 'col-md-6'}>
                    <div className="form-group">
                        <label className='control-label'>{schema.title}</label>
                        <div>
                            <DatePicker required={this.props.required}
                                        schema={schema}
                                        uiSchema={uiSchema}
                                        autoFocus={uiSchema.autofocus}
                                        onChange={this.props.onChange}
                                        value={value || ''}
                                        ref={this.controlRef}/>
                        </div>
                    </div>
                </div>
            </div>)
    }
}
