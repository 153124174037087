export const testValidator = (item) => {
    console.log(item)
    return {
        message: `test`,
        validate: (value) => true
    }
}

export const required = (title)=> {
    return {
        message: `${title} is required`,
        validate: (value) => !!value
    }
}

export const maxLength = (length) => {
    return {
        message: `Max Length for account name is: ${length}`,
        validate: (value) => value.length <= length
    }
}