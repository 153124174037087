import * as React from "react";
import PropTypes from 'prop-types';
import TextBox from "../elements/TextBox";

export default class FormText extends React.Component {
    state = {
        isValid: false,
        isDirty: false,
    };
    controlRef = React.createRef();


    isValid() {
        return this.controlRef.current.isValid(this.props.value);
    }


    render() {
        const {value, schema, uiSchema = {}} = this.props;
        return (
            <div className="row">
                <div className={uiSchema.size || 'col-md-6'}>
                    <div className="form-group">
                        <label className="form-label">{schema.title}</label>
                        <span className="help">e.g. "5000"</span>

                        <TextBox
                            required={this.props.required}
                            schema={schema}
                            uiSchema={uiSchema}
                            autoFocus={uiSchema.autofocus}
                            onChange={this.props.onChange}
                            value={value || ''}
                            ref={this.controlRef}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

FormText.propTypes = {
    onChange: PropTypes.func
}
