import React, {useEffect, useState} from "react";
import Box from "../components/layout/Box";
import {backend} from "../_config";
import req from "../utils/req";

export function AccountTypeSummary() {
    const [accountTypes, setAccountTypes] = useState([]);

    async function fetchData() {
        req.get(`${backend}/reports/account-type-summary`).then((res) => {
            setAccountTypes(res.data)
        })
    }

    useEffect(() => {
        fetchData()
    }, []);

    function accountTypeData(type) {
        return <>
            <tr>
                <td colSpan='3' style={{background: '#f7f7f7', padding: '8px 6px', fontWeight: 'bold'}}>
                    {type}
                </td>
            </tr>
            {accountTypes[type] && accountTypes[type].map(a => <tr>
                <td>{a.name}</td>
                <td>{a.debt}</td>
                <td>{a.credit}</td>
            </tr>)}
        </>;
    }

    return <Box>
        <box-header>
            Account Type Summary
        </box-header>
        <box-body>
            <table className="table">
                <thead>
                <tr>
                    <th>Account Type</th>
                    <th>Debt</th>
                    <th>Credit</th>
                </tr>
                </thead>
                <tbody>
                {accountTypeData('Asset')}
                {accountTypeData('Equity')}
                {accountTypeData('Income')}
                {accountTypeData('Expense')}
                {accountTypeData('Liability')}
                </tbody>
            </table>

        </box-body>
    </Box>
}
