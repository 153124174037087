import React from "react";
import Box from "../../components/layout/Box";
import {DataGrid} from "../../components/data-grid/DataGrid";
import {backend} from "../../_config";

export function ChartOfAccounts() {

    const settings = {
        url: `${backend}/accounts`,
        key: 'id',
        entity: 'account',
        columns: [
            {
                title: 'Account Name',
                path: 'name',
                template: (data, index) => {
                    return <td key={index} data-test={`name-${index + 1}`}
                               className={`name-${index}`}><a
                        href={`accounts/${data.id}`}>{data.name}</a></td>
                }
            },
            {
                title: 'Code',
                path: 'code'
            },
            {
                title: 'Account Type',
                path: 'account_type.name'
            },

        ]
    }

    return <div>
        <Box>
            <box-header>Chart Of Accounts</box-header>
            <box-body>
                <DataGrid settings={settings}/>
            </box-body>
            <box-footer>
            </box-footer>
        </Box>
    </div>

}
