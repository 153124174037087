import axios from "axios";

let config = {};
if (localStorage.jwtToken) {
    config = {
        headers: {
            Authorization:
                `Bearer ${JSON.parse(localStorage.jwtToken)}`
        }
    };
}

const req = {
    get: (url) => {
        return axios.get(url, config);
    },
    post: (url, data) => {
        return axios.post(url, data, config)
    },
    put: (url, data) => {
        return axios.put(url, data, config)
    },
    delete: (url) => {
        return axios.delete(url, config)
    }
}


export default req;