import * as React from "react";

export default class Control extends React.Component {
    handleChange = (value) => {
        const isValid = this.isValid(value);
        return this.props.onChange(value);
    };

    handleBlur = (value) => {
        this.isValid(value);
        this.setState({isDirty: true})
    }


    isValid(value) {
        if (!value) {
            value = this.props.value || this.props.selectedItem;
        }

        const validations = this.props.schema.validations;
        if (validations && validations.length > 0) {
            for (let i = 0; i <= validations.length - 1; i++) {
                const validator = validations[i];
                const isValid = validator.validate(value)
                if (!isValid) {
                    this.setState({errorMessage: validator.message, isValid: false, isDirty: true});
                    return false;
                }
            }
        }
        this.setState({errorMessage: '', isValid: true});

        return true;
        // debugger;
        //
        // const {required, title} = this.props.schema;
        // if (required) {
        //     if (!value) {
        //         this.setState({errorMessage: `${title} is required`, isValid: false, isDirty: true});
        //         return false;
        //     }
        // }
        // this.setState({errorMessage: '', isValid: true});

        return true;
    }

}
