import React, {useRef, useState} from 'react';
import Box from "../components/layout/Box";
import {required} from "../components/validators";
import axios from "axios";
import {Form} from "../components/forms/Form";
import {backend} from "../_config";
import auth from "../utils/auth";

export default function LoginPage(props) {
    const [object, setObject] = useState({})
    const formRef = useRef(null);

    const schema = {
        title: "Login",
        type: "object",

        properties: {
            identifier: {
                type: "string", title: "User Name", validations: [
                    required('Password'),
                ]
            },
            password: {
                type: "string", title: "Password", validations: [
                    required('Password'),
                ]
            },
        }
    }

    const uiSchema = {}

    const onFormChange = (val) => {
        setObject(val);
    }

    const redirectUser = () => {
        props.history.push('/');
    }


    const login = (e) => {
        debugger;
        e.preventDefault()
        if (formRef.current.isValid()) {
            axios.post(`${backend}/auth/local`, object).then(res => {
                debugger;
                auth.setToken(res.data.jwt, true); // todo remember me
                auth.setUserInfo(res.data.user, true);
                redirectUser();
            })
        }

    }

    return <Box>
        <box-header>Login</box-header>
        <box-body>
            <Form schema={schema} uiSchema={uiSchema} onChange={onFormChange} ref={formRef} value={object}>
            </Form>
        </box-body>

        <box-footer>
            <button type="submit" className="btn btn-success"
                    data-test='login'
                    onClick={(e) => login(e)}>Login
            </button>
        </box-footer>
    </Box>
}

// export default class LoginPage extends React.Component {
//     constructor(props) {
//         super(props);
//     }
//
//     render() {
//         console.log('hi')
//         return <div>
//             <Box>
//                 <box-header>Home</box-header>
//             </Box>
//         </div>
//     }
//
//
// }