import * as React from "react";
import FormText from "./FormText";
import {FormDate} from "./FormDate";
import {FormSelect} from "./FormSelect";
import FormRelationship from "./FormRelationship";
import {FormArray} from "./FormArray";

export class Form extends React.Component {
    state = {
        formData: {}
    }

    refs = [];

    componentWillReceiveProps(nextProps) {
        const formData = {...nextProps.value}
        this.setState({formData});
    }


    onChange(key, value) {
        const formData = {...this.state.formData};
        formData[key] = value;

        this.setState({formData}, () => {
            this.props.onChange(this.state.formData);
        })
    }

    isValid() {
        const isValid = this.refs.map(a => a.current.isValid())
            .reduce((prev, current) => prev && current, true);
        return isValid;
    }


    render() {
        this.refs = Object.keys(this.props.schema.properties).map(a => React.createRef())
        return (
            <div>
                {
                    Object.keys(this.props.schema.properties).map((key, index) => {
                        const schema = this.props.schema.properties[key];
                        schema.key = key;
                        const uiSchema = this.props.uiSchema[key];
                        let control;
                        switch (schema.type) {
                            case 'string':
                                if (schema.enum) {
                                    return <FormSelect
                                        schema={schema}
                                        uiSchema={uiSchema}
                                        value={this.state.formData[key]}
                                        onChange={value => this.onChange(key, value)}
                                        ref={this.refs[index]}
                                    />
                                }
                                control = <FormText data-test={key}
                                                    schema={schema}
                                                    uiSchema={uiSchema}
                                                    value={this.state.formData[key]}
                                                    onChange={value => this.onChange(key, value)}
                                                    ref={this.refs[index]}
                                />
                                return control;
                            case 'date':
                                return <FormDate
                                    schema={schema}
                                    uiSchema={uiSchema}
                                    value={this.state.formData[key]}
                                    onChange={value => this.onChange(key, value)}
                                    ref={this.refs[index]}
                                />
                            case 'relationship':
                                return <FormRelationship
                                    schema={schema}
                                    uiSchema={uiSchema}
                                    value={this.state.formData[key]}
                                    onChange={value => this.onChange(key, value)}
                                    ref={this.refs[index]}
                                />

                            case 'array':
                                return <FormArray
                                    schema={schema}
                                    uiSchema={uiSchema}
                                    value={this.state.formData[key]}
                                    ref={this.refs[index]}
                                    onChange={value => this.onChange(key, value)}/>
                            // return schema.items.map((f, index) => <Form schema={f}
                            //                                        uiSchema={uiSchema[key]}
                            //                                        onChange={value => this.onChange(key, value, index)}
                            // />)

                            default:
                                return ""
                        }

                    })
                }

            </div>
        );
    }
}
