import React from "react";
import Box from "../../components/layout/Box";
import {DataGrid} from "../../components/data-grid/DataGrid";
import {backend} from "../../_config";

export function ManualJournals() {
    const settings = {
        url: `${backend}/journals`,
        key: 'id',
        entity: 'Journal',
        columns: [
            {
                title: 'Date',
                path: 'date',
                template: (data, index, colIndex) => {
                    return <td key={`date-${index}-${colIndex}`} data-test={`date-${index + 1}`}
                               className={`date-${index}`}><a
                        href={`journals/${data.id}`}>{data.date}
                    </a>
                    </td>
                }
            },
            {
                title: 'Notes',
                path: 'notes'
            },
            {
                title: 'Amount',
                template: (data, index, colIndex) => {
                    return <td key={`amount-${index}-${colIndex}`} data-test={`amount-${index + 1}`}
                               className={`amount-${index}`}>
                        {data.journalItems.reduce((a, i) => i.debt + a, 0)}
                    </td>
                }
            }

        ]
    }
    return <Box>
        <box-header>
            Manual Journals
        </box-header>
        <box-body>
            <DataGrid settings={settings}/>
        </box-body>
    </Box>
}
