import React from "react";
import TextBox from "../elements/TextBox";
import FormRelationship from "./FormRelationship";
import Relationship from "../elements/Relationship";
import {FormArrayRow} from "./FormArrayRow";
import {ValidationMessage} from "./ValidationMessage";

export class FormArray extends React.Component {
    state = {
        items: [{}]
    }
    refs = [];

    componentWillReceiveProps(nextProps) {
        if (nextProps.value) {
            const items = [...nextProps.value]
            this.setState({items});
        }
    }

    isValid() {
        let isValid = this.refs.map(a => a.current.isValid()).reduce((prev, current) => prev && current, true);


        const validations = this.props.schema.validations;
        const results = true;
        if (validations && validations.length > 0) {
            for (let i = 0; i <= validations.length - 1; i++) {
                const validator = validations[i];
                const isValid = validator.validate(this.state.items)
                if (!isValid) {
                    this.setState({errorMessage: validator.message, error: true});
                    return false;
                }
            }
        }

        this.setState({errorMessage: '', error: false});
        return isValid && results;
    }

    onChange(key, value, index) {
        const items = [...this.state.items];
        items[index][key] = value
        this.setState({items}, () => {
            // this.isValid()
            this.props.onChange(items);
        })
    }

    addItem() {
        const items = [...this.state.items, {}];
        this.setState({items});
    }

    removeItem(index) {
        let items = [...this.state.items];
        items.splice(index, 1)
        this.setState({items});
    }

    render() {
        const {schema, uiSchema = {}} = this.props;
        this.refs = this.state.items.map(a => React.createRef())

        return <div>
            <h3>{this.props.schema.title}</h3>
            <ValidationMessage schema={schema} invalid={this.state.error} message={this.state.errorMessage}/>
            <table className="table table-bordered">
                <thead>
                <tr>
                    {Object.keys(this.props.schema.items.properties).map(a => <th>{a}</th>)}
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {this.state.items.map((a, index) =>
                    <FormArrayRow
                        schema={schema}
                        uiSchema={uiSchema}
                        index={index}
                        item={a}
                        onChange={(key, value) => this.onChange(key, value, index)}
                        onRemoveItem={() => this.removeItem(index)}
                        ref={this.refs[index]}
                    />
                )}
                <tr>
                    <td>
                        <input type="text" className="btn btn-default" value="Add" onClick={() => this.addItem()}/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        // return this.state.items.map(item=> {
        //     return <div>This is array item</div>
        // })
    }

    getValue(k, index) {
        return this.state.items[index][k];
    }
}
