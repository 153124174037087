import React, {useRef} from "react";
import Box from "../components/layout/Box";
import {Form} from "../components/forms/Form";
import {backend} from "../_config";

export function Home() {
    const formRef = useRef();
    const url = `${backend}/account-types`

    const colourOptions = [
        {value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true},
        {value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true},
        {value: 'purple', label: 'Purple', color: '#5243AA'},
        {value: 'red', label: 'Red', color: '#FF5630', isFixed: true},
        {value: 'orange', label: 'Orange', color: '#FF8B00'},
        {value: 'yellow', label: 'Yellow', color: '#FFC400'},
        {value: 'green', label: 'Green', color: '#36B37E'},
        {value: 'forest', label: 'Forest', color: '#00875A'},
        {value: 'slate', label: 'Slate', color: '#253858'},
        {value: 'silver', label: 'Silver', color: '#666666'},
    ];

    const flavourOptions = [
        {value: 'vanilla', label: 'Vanilla', rating: 'safe'},
        {value: 'chocolate', label: 'Chocolate', rating: 'good'},
        {value: 'strawberry', label: 'Strawberry', rating: 'wild'},
        {value: 'salted-caramel', label: 'Salted Caramel', rating: 'crazy'},
    ];

    const schema = {
        title: "Contacts",
        type: "object",
        required: ["firstName"],

        properties: {
            firstName: {type: "string", title: "First Name", required: true},
            lastName: {type: "string", title: "Last Name"},
            sex: {
                type: "string", title: "sex", enum: [
                    {label: "male", value: "male"},
                    {label: "female", value: "female"}
                ],
                required: true
            },
            groupedOptions: {
                type: "string", title: "groubed", enum: [
                    {
                        label: 'Colours',
                        options: colourOptions,
                    },
                    {
                        label: 'Flavours',
                        options: flavourOptions,
                    },
                ],
                required: true
            },
            accounts: {type: "relationship", title: "Account"},
            currentDate: {type: "date", title: "Current Date", required: true},
            orderItems: {
                type: "array",
                title: "Order Details",
                items: {
                    type: "object",
                    properties: {
                        firstName: {type: "string", title: "First Name", required: true},
                        lastName: {type: "string", title: "Last Name"},
                        // todo: fix relationship ui source
                        // accounts: {type: "relationship", title: "Contacts", source: url, label: 'name', value: "id"},
                    }
                }
            }
        }
    }

    const uiSchema = {
        firstName: {
            size: "col-md-4"
        },
        lastName: {
            size: "col-md-4"
        },
        currentDate: {
            size: "col-md-4"
        },
        sex: {
            size: "col-md-4"
        },
        accounts: {
            source: url, label: 'name', value: "id"
        },
    }

    const onFormChange = (val) => {
        console.log({finalValue: val})
    }

    const options = [
        {value: 'chocolate', label: 'Chocolate'},
        {value: 'strawberry', label: 'Strawberry'},
        {value: 'vanilla', label: 'Vanilla'}
    ]

    // const submitForm = () => {
    //     console.log(formRef.current.isValid())
    //     // formRef.
    // }

    return <div>
        <Box>
            <box-header>Home</box-header>
            <box-body>
                <Form schema={schema} uiSchema={uiSchema} onChange={onFormChange} ref={formRef}>
                </Form>
            </box-body>
        </Box>
    </div>

}
