import React from "react";
import {ValidationMessage} from "./ValidationMessage";
import {SelectElement} from "../elements/SelectElement";
import Control from "../elements/Control";

export class FormSelect extends Control {
    state = {
        isValid: false,
        isDirty: false,
        isOpen: false
    };


    render() {
        const {value, schema, uiSchema = {}} = this.props;
        const {isValid, isDirty} = this.state;
        const error = !isValid && isDirty;

        return (
            <div className="row">
                <div className={uiSchema.size || 'col-md-6'}>
                    <div className="form-group">
                        <label className="form-label">{schema.title}</label>
                        <span className="help">e.g. "5000"</span>

                        <SelectElement
                            schema={schema}
                            uiSchema={uiSchema}
                            onChange={({value})=> this.handleChange(value)}
                            value={value}
                            options={schema.enum}
                        />
                        <ValidationMessage schema={schema} invalid={error} message={this.state.errorMessage}/>
                    </div>
                </div>
            </div>
        )
    }
}
